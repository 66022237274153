import React from "react"
import Layout from "../components/layout"
import {
  useColorMode,
  Container,
  Grid,
  Flex,
  Heading,
  Text,
  Box,
} from "theme-ui"
import Deck from "../components/display/deck"
import { StaticImage } from "gatsby-plugin-image"
import HalfEarthLogo from "../images/products/half-earth-logo.png"
import CalmWithinCover from "../images/products/calm-within-cover.png"
import ButtonLink from "../components/inputs/buttonLink"

import { Icon } from "@iconify/react"
import "./products.css"

export const Head = () => (
  <>
    <title>Products</title>
    <meta name="description" content="Products" />
  </>
)

const Products = () => {
  const [colorMode] = useColorMode()
  const isDark = colorMode === "dark"
  const color = isDark ? "#e4cd88" : "#494091"
  const boxShadowPercent = isDark ? "30" : "10"
  return (
    <Layout variant="sidebar">
      <Container
        variant="primary"
        sx={{
          width: ["80%", "80%", "90%", "80%"],
          margin: "0 auto",
          padding: "4em 0.4em",
        }}
      >
        <Grid
          gap={[2, 2, 3, 1]}
          row={[2, 2, 1, 1]}
          columns={[1, 1, 1, 2]}
          sx={{ padding: ["0em", "3em"] }}
        >
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              margin: "2em 1em",
            }}
          >
            <Heading
              as="h1"
              variant="h1"
              sx={{ fontSize: ["24px", "28px", "34px"] }}
            >
              Why are we so patient with our rescue animals, yet so easily
              triggered and disrespectful to our human loved ones?
            </Heading>
            <Text as="p" variant="paragraphCenter">
              Most of us cherish animals. If we keep in mind that we humans are
              animals—Homo sapiens—we might begin to soften our hearts toward
              the other human animals with whom we live and work. Neuroscience
              has been helping mental health practitioners understand the inner
              workings of our behavior patterns and the triggers brought on by
              our past. With this understanding comes ways to calm and take
              advantage of the super-evolution that we humans have harnessed
              over time. We can settle our angst using nature-based approaches
              and psychotherapy to cope more effectively with the
              ever-intensifying landscape of our lives.
            </Text>
            <Text as="p" variant="paragraphCenter">
              Deborah Weir, Ph.D., has been a professional mental health
              counselor for 30 years, and this book is an amalgamation of her
              approach, based on neuroscience, attachment theory, and
              depth-oriented “soulwork.” While in counselor training, Deb fell
              into a crisis that inspired her to learn wilderness survival. She
              combined her nature-based teachings with traditional counseling
              techniques, resulting in her current model. As you read, take a
              walk around her “Medicine Wheel” of healing and learn how to calm
              your inner animal, allowing access to your full evolutionary
              power!
            </Text>
            <Text as="p" variant="paragraphCenter">
              Deb’s counseling approach has something for everyone—eyes-open
              hypnotherapy, relationship therapy, trauma interventions,
              addiction management, dream interpretation, calming rituals, and
              much more. She includes many personal experiences as she “walks
              her talk.” Learn about your instinctual nature so you can evolve
              to your highest level of human behavior!
            </Text>
          </Flex>
          <Flex
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: ["center", "center", "center"],
              minHeight: "600px",
              padding: "3em",
              maxWidth: ["500px", "800px"],
              justifySelf: "center",
            }}
          >
            <img src={CalmWithinCover} alt="Calm Within Cover" />
            <Text as="p" variant="p" sx={{ fontSize: ["18px", "20px"] }}>
              <Text as="span" variant="emphasize">
                Price:
              </Text>{" "}
              $15.00
            </Text>
            <ButtonLink to="https://buy.stripe.com/bIY03bgn5ePbbAI000">
              Order Now
            </ButtonLink>
          </Flex>
        </Grid>
        <Text
          as="p"
          variant="p"
          sx={{
            fontStyle: "italic",
            fontSize: ["18px", "20px"],
            margin: ["2em 1em", "0 4em"],
          }}
        >
          {" "}
          “…we humans have an instinctual animal within, difficult to control
          when we feel threatened. We also have a complex system of higher-level
          skills which we can train ourselves to access. If you haven’t
          developed these practices already, now’s the time to pick a method
          that will help you start calming your exhausting alarms. Do some
          diaphragmatic breathing. Take a deep breath, close your eyes, and tune
          into what’s inside. These inner automated responses can operate in a
          positive way with awareness and re-programming. It is our nature to be
          animal, but studies show it is also possible for us to evolve by
          grounding ourselves and calming, enabling us to function with our
          whole, gifted brains.” 
        </Text>
        <Grid
          gap={[2, 2, 3, 1]}
          row={[2, 2, 1, 1]}
          columns={[1, 1, 1, 2]}
          sx={{ padding: ["0em", "3em"] }}
        >
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              margin: "5em 1em 1em",
            }}
          >
            <Heading as="h1" variant="h1" sx={{ textAlign: "center" }}>
              Half-Earth Project
            </Heading>
            <Text as="p" variant="paragraphCenter">
              Deb has aligned herself with{" "}
              <a href="https://www.half-earthproject.org/">
                E. O. Wilson’s Half-Earth Project
              </a>{" "}
              by donating partial book sales profits to the E. O. Wilson
              Foundation. (See Chapter 10 for more information on this.) When
              ordered directly from Deb, she will personally sign your book and
              ensure $5 of the profits will be donated.
            </Text>
          </Flex>
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: "center",
              minHeight: ["300px", "600px"],
            }}
          >
            <img
              src={HalfEarthLogo}
              alt="Half-Earth Logo"
              style={{ width: "350px" }}
            />
          </Flex>
        </Grid>
        <Grid
          gap={[0, 0, 4, 5]}
          row={[1, 1, 1, 1]}
          columns={[1, 1, 1, "8fr 5fr"]}
          sx={{ padding: ["2em 0em", "3em 0"] }}
        >
          <Flex sx={{ flexDirection: "column" }}>
            <Heading as="h2" variant="upperHeading">
              Exploring Your Primal Nature
            </Heading>
            <Heading as="h1" variant="h1Centered" className="soft-faux-font">
              Therapy Cards
              <Icon
                icon="mdi:cards"
                color={color}
                variant="icon"
                className="icon rotate"
              />
            </Heading>
            <Text as="p" variant="paragraphCenter">
              <Text as="span" variant="emphasize">
                Exploring Your Primal Nature{" "}
              </Text>
              is an outstanding experiential group therapy process using a deck
              of specially-designed cards that teaches basic ecopsychology
              concepts while asking the reader to share related personal,
              interesting, and fun information with fellow group members.
            </Text>
            <Text as="p" variant="paragraphCenter">
              The cards ensure an interesting way for groups to connect with
              each other, while driving home basic metaphorical themes related
              to living life with awareness. The use of the cards can be adapted
              to fit any nature-based group.
            </Text>
            <Flex
              className="mobile-flex"
              style={{
                justifyContent: "center",
                minHeight: "800px",
                margin: "3em auto 2em",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Flex
                style={{
                  justifyContent: "center",
                  minHeight: "600px",
                  margin: "3em auto 2em",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Deck />
                <Text variant="caption">Actual cards from the deck!</Text>
              </Flex>
              <Text as="p" variant="p" sx={{ fontSize: ["18px", "20px"] }}>
                <Text as="span" variant="emphasize">
                  Price:
                </Text>{" "}
                $15.00
              </Text>

              <ButtonLink to="https://buy.stripe.com/cN2cPX9YH5eB8ow6oq">
                Order Now
              </ButtonLink>
            </Flex>
            <Text as="p" variant="paragraphCenter">
              The deck includes{" "}
              <Text as="span" variant="emphasize">
                39 illustrated cards
              </Text>{" "}
              that feature an outdoor survival mini-lesson and related personal
              question to answer, spanning themes such as nature awareness,
              shelter, water, fire, edibles, animal totems, plant totems,
              phobias, disasters, outdoor recreation, nature symbolism, personal
              development, and current environmental issues. Included are
              <Text as="span" variant="emphasize">
                {" "}
                nine blank cards
              </Text>{" "}
              that can be individualized by the group leader for a more specific
              group focus. Instructions include ideas for adapting the way the
              cards are used for variety.
            </Text>
            <Text as="p" variant="paragraphCenter">
              This group therapy tool is small and easy to fit in any backpack.
              It can be used for groups of teens and adults by facilitators of
              nature retreats, wilderness therapy experientials, substance abuse
              counseling groups, and other therapeutic gatherings.
            </Text>
          </Flex>
          <Flex
            className="desktop-flex"
            style={{
              alignItems: "center",
              justifyContent: "start",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                minWidth: "300px",
                display: "flex",
                alignItems: "start",
                justifyContent: "center",
                minHeight: "600px",
              }}
            >
              <Deck />
            </Box>
            <Text as="p" variant="p" sx={{ fontSize: ["18px", "20px"] }}>
              <Text as="span" variant="emphasize">
                Price:
              </Text>{" "}
              $15.00
            </Text>

            <ButtonLink to="https://buy.stripe.com/cN2cPX9YH5eB8ow6oq">
              Order Now
            </ButtonLink>
          </Flex>
        </Grid>
        <Grid
          gap={[2, 2, 3, 5]}
          row={[1, 1, 1, 1]}
          columns={[1, 1, 1, "6fr 8fr"]}
          sx={{ padding: ["5em 0em 1em", "3em 0"], marginTop: [0, "5em"] }}
        >
          <Box>
            <Flex
              sx={{
                flexDirection: "column",
                alignItems: ["center", "center", "center", "start"],
                marginBottom: "5em",
              }}
            >
              <StaticImage
                src="../images/products/book_cover.jpg"
                alt="women in the wilderness book cover"
                placeholder="blurred"
                layout="fixed"
                height={450}
                className={isDark ? "dark book" : "light book"}
                style={{
                  overflow: "hidden",
                  transform: "rotate(358deg)",
                  boxShadow:
                    "1px 1px 10px 5px rgb(19 11 46 / " +
                    boxShadowPercent +
                    "%)",
                }}
              />

              <Heading as="h2" variant="upperHeading">
                Exploring Your Primal Nature
              </Heading>
              <Heading as="h1" variant="h1Centered" className="soft-faux-font">
                Women in the Wilderness
              </Heading>

              <Text
                as="p"
                variant="p"
                sx={{
                  fontSize: ["18px", "20px"],
                  textAlign: ["center", "center", "center", "left"],
                }}
              >
                <Text as="span" variant="emphasize">
                  Price:
                </Text>{" "}
                $15.00
              </Text>

              <ButtonLink to="https://buy.stripe.com/eVacPX7Qz6iFfQYaEF">
                Order Now
              </ButtonLink>
            </Flex>
          </Box>
          <Box>
            <Heading as="h3" variant="h1Centered" className="soft-faux-font">
              Spend a Day in Nature And Balance Your Energy Fields
            </Heading>
            <Text as="p" variant="quote">
              "Retreats come in all different forms: a hot tub experience, a
              quiet jog, a touching movie watched on the sofa late at night, a
              day of writing at home, a girls’ weekend. It is a time to be quiet
              and reconnect with our inner self. Too much of it is called
              isolation and is destructive to our well-being. Too little of it
              is called being out of balance."
            </Text>
            <Text as="p" variant="paragraph">
              Are you a woman in need of a break? Do you long for just one day
              and evening to re-connect with who you really are? Have you been
              pulled in so many different directions that your energy is waning?
              Then it's time to indulge yourself with
              <Text as="span" variant="italicize">
                {" "}
                Women In The Wilderness: Exploring Your Primal Nature
              </Text>{" "}
              written by wilderness therapist{" "}
              <Text as="span" variant="emphasize">
                Deborah Weir, PhD, LCMHCS
              </Text>
              .
            </Text>
            <Text as="p" variant="paragraph">
              Deb, as she is called in the field, is a very feminine wilderness
              survivalist and psychotherapist on a mission to help promote a
              more peaceful earthly existence. She idealizes that the only way
              to affect change is to bring feminine, nurturing consciousness to
              the psyche of individuals to balance out the masculine forces that
              have dominated us for eons of time. Since the natual world is the
              primary feminine setting, herein lies the key. For years she has
              been encouraging women to find feminine solace and empowerment in
              the wilderness through nature retreats and wilderness therapy,
              while the ever-disappearing natural world is still available to
              enjoy. Deb's pushing a natural drug called wilderness magic, where
              women (and men) can find insight, healing, and stress management
              by connecting with the natural world.
            </Text>
            <Text as="p" variant="paragraph">
              Based on an actual woman's retreat in a lush Florida forest, this
              book is designed to be a form of a retreat, simulating a woman's
              getaway. The soothing presentations, photos, artwork, quotes, and
              poetry provide entrancing suggestions to place the reader in the
              middle of an actual retreat. From opening the front cover and
              finding an invitation, to listening to the audio meditation
              provided, to getting to know the attendees, the reader is given a
              vicarious experience. Included are enticing invitations along the
              way for the reader to join the various activities in the form of a
              personal solo retreat. If you've ever considered attending a
              woman's group retreat, this book experience can be a safe method
              of exploration.
            </Text>
          </Box>
        </Grid>
      </Container>
    </Layout>
  )
}
export default Products
